<template>
  <div>
    <HeaderPanel
      title="Prospect"
      @sidebar="$refs.prospectFilter.show()"
      :hasDropdown="false"
      :hasSearch="false"
      :hasExport="true"
      @export="exportFile()"
    />
    <div class="bg-white p-3 mt-3">
      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
        @sort-changed="sortingChanged"
        :sort-by.sync="filters.sortBy"
        no-local-sorting
        :sort-desc.sync="filters.sortDesc"
      >
        <template v-slot:cell(first_name_th)="data">
          <div>{{ data.item.first_name_th }} {{ data.item.last_name_th }}</div>
          <!-- <div class="text-center">
            <small>{{ data.item.member_id }}</small>
          </div> -->
        </template>
        <template v-slot:cell(member)="data">
          <span>
            {{ data.item.member | numeral("0,0.00") }}
            ({{ data.item.percen_member }}%)
          </span>
        </template>

        <template v-slot:cell(revenue)="data">
          <span>
            {{ data.item.revenue | numeral("0,0.00") }} ({{
              data.item.percen_revenue
            }}%)
          </span>
        </template>
        <template v-slot:cell(bills)="data">
          <span>
            {{ data.item.bills | numeral("0,0") }}({{
              data.item.percen_bills
            }}%)
          </span>
        </template>
        <template v-slot:cell(units)="data">
          <span>
            {{ data.item.units | numeral("0,0") }}
            ({{ data.item.percen_units }}%)
          </span>
        </template>
        <!-- 
      <template v-slot:foot()="data">
        <span v-if="stat.total_store_table[data.field.key]" class="text-center"
          >{{ stat.total_store_table[data.field.key] | numeral("0,0.00") }}
        </span>
        <span v-else>
          <div class="text-left px-2" v-if="data.field.text">
            {{ data.field.text }}
          </div>
        </span>
      </template> -->
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="handleChangeTakeProduct"
        @pagination="paginationProduct"
        :filter="filters"
        :rows="rows"
        :limit="2"
      />
      <SideBarFilter
        id="prospectFilter"
        ref="prospectFilter"
        :filter="filters"
        :placeholder="'Search Name'"
        @clearFilter="clearFilter"
        @searchAll="filterActions"
        :hideStatusFilter="false"
        :hideSearchBar="true"
      >
        <template v-slot:filter-option>
          <div class="mt-3">
            <b-input-group>
              <b-form-input
                type="text"
                id="header-search-bar"
                class="search-bar"
                @keyup.enter="handleSearch"
                placeholder="Search Customer name, Telephone, Invoice No. "
                v-debounce:500ms="autoSearch"
                v-model="filters.Search"
              >
              </b-form-input>
              <b-input-group-append is-text class="mr-2">
                <b-iconstack font-scale="2" type="submit" @click="handleSearch">
                  <b-icon
                    stacked
                    icon="search"
                    scale="0.5"
                    variant="grey"
                  ></b-icon>
                </b-iconstack>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="mt-3">
            <div>
              <div class="main-label">Register Date From</div>
              <div :class="['input-container']">
                <datetime
                  format="dd/MM/yyyy"
                  v-model="filters.RegisterDateFrom"
                  ref="startDate"
                  placeholder="Please Select Date"
                >
                </datetime>
                <div
                  class="icon-primary text-right"
                  @click="$refs.startDate.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="pointer color-primary"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </div>
            <div class="mt-3 mb-3">
              <div class="main-label">Register Date To</div>
              <div :class="['input-container']">
                <datetime
                  format="dd/MM/yyyy"
                  v-model="filters.RegisterDateTo"
                  :min-datetime="filters.RegisterDateFrom"
                  placeholder="Please Select Date"
                  ref="endDate"
                >
                </datetime>
                <div
                  class="icon-primary text-right"
                  @click="$refs.endDate.isOpen = true"
                >
                  <font-awesome-icon
                    icon="calendar-alt"
                    class="pointer color-primary"
                    color="#B41BB4"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </SideBarFilter>
    </div>
  </div>
</template>

<script>
import HeaderPanel from "@/components/HeaderPanel";
export default {
  components: { HeaderPanel },
  data() {
    return {
      fields: [
        {
          key: "member_id",
          label: "Member ID",

          sortable: true,
        },
        // // {
        // //   key: "member_tier",
        // //   label: "Member Tier",

        // //   sortable: true,
        // // },
        {
          key: "first_name_th",
          label: "Customer Name",
          // tdClass: "text-left",
          sortable: true,
        },
        // {
        //   key: "last_name_th",
        //   label: "Lastname",

        //   sortable: true,
        // },
        {
          key: "email",
          label: "Email",

          sortable: true,
        },
        {
          key: "telephone",
          label: "Telephone",

          sortable: true,
        },
        {
          key: "birthday",
          label: "Birthday",

          sortable: true,
        },
        {
          key: "age",
          label: "Age",
          class: "w-f-80px",
          sortable: true,
        },
        {
          key: "gender",
          label: "Gender",

          sortable: true,
        },
        {
          key: "consent_status",
          label: "Consent Status",

          sortable: true,
        },
        {
          key: "register_date",
          label: "Registered Date",
          class: "w-f-80px",
          sortable: true,
        },
      ],
      rows: 0,
      items: [],
      filters: {
        RegisterDateFrom: "",
        RegisterDateTo: "",
        Search: "",
        PageNumber: 1,
        RecordPerPage: 15,
        SortColumnId: 0,
        take: 10,
        page: 1,
        sortBy: "",
        sortDesc: "",
        SortType: false, // true = ASC, flase = DESC
      },
      isBusy: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isBusy = true;
      let payload = { ...this.filters };
      if (payload.RegisterDateFrom) {
        payload.RegisterDateFrom = this.$moment(
          payload.RegisterDateFrom
        ).format("YYYY-MM-DD");
      }
      if (payload.RegisterDateTo) {
        payload.RegisterDateTo = this.$moment(payload.RegisterDateTo).format(
          "YYYY-MM-DD"
        );
      }
      payload.PageNumber = payload.page;
      payload.RecordPerPage = payload.take;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/DashBoard/prospect`,
        payload
      );
      this.items = res.data.detail.data;

      this.rows = res.data.detail.total;
      this.isBusy = false;
    },
    sortingChanged(ctx) {
      let index = this.fields.findIndex((el) => el.key == ctx.sortBy);
      index = index + 1;

      if (
        this.filters.SortType &&
        !ctx.sortDesc &&
        this.filters.SortColumnId == index
      ) {
        this.filters.SortColumnId = 0;
        this.filters.sortBy = "";
        this.filters.sortDesc = "";
      } else {
        this.filters.SortColumnId = index;
        this.filters.SortType = ctx.sortDesc;
      }
      this.getData();
    },
    paginationProduct(val) {
      this.filters.page = val;
      this.getData();
    },
    handleChangeTakeProduct(val) {
      this.filters.take = val;
      this.filters.page = 1;
      this.getData();
    },
    clearFilter() {
      this.filters = {
        RegisterDateFrom: "",
        RegisterDateTo: "",
        Search: "",
        PageNumber: 1,
        RecordPerPage: 15,
        SortColumnId: 0,
        take: 10,
        page: 1,
        sortBy: "",
        sortDesc: "",
        SortType: false, // true = ASC, flase = DESC
      };
      this.$refs.prospectFilter.hide();
      this.getData();
    },
    filterActions() {
      this.getData();
    },
    handleSearch() {
      this.getData();
    },
    autoSearch() {
      this.getData();
    },
    async exportFile() {
      let payload = { ...this.filters };
      if (payload.RegisterDateFrom) {
        payload.RegisterDateFrom = this.$moment(
          payload.RegisterDateFrom
        ).format("YYYY-MM-DD");
      }
      if (payload.RegisterDateTo) {
        payload.RegisterDateTo = this.$moment(payload.RegisterDateTo).format(
          "YYYY-MM-DD"
        );
      }
      payload.PageNumber = payload.page;
      payload.RecordPerPage = payload.take;
      const res = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/DashBoard/report/prospect`,
        payload,
        {
          responseType: "blob",
        }
      );
      let data = res;
      console.log(data);
      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `ProspectReport.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
};
</script>

<style></style>
